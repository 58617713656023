import React, { FC, useContext, useState } from 'react';
import { Button, Popconfirm, Popover, Space } from 'antd';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { LanguageContext } from '@app/global_provider';
import { deletePrivateSafebox } from '@app/api/safeboxes';
import { SafeboxModal } from '@app/components/modals/safebox_modal/safebox-modal';
import { Safebox } from '@app/types';

import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as SettingIcon } from '@app/assets/icons/setting-icon.svg';
import { ReactComponent as EditNameIcon } from '@app/assets/icons/rename-icon.svg';
import { ReactComponent as HistoryIcon } from '@app/assets/icons/history-icon.svg';
import { useNavigate } from 'react-router-dom';

interface SafeboxActionsProps {
  setEditBoxId?: (id: string) => void;
  safebox: Safebox;
}

export const SafeboxActions: FC<SafeboxActionsProps> = ({ setEditBoxId, safebox }) => {
  const { isRtl } = useContext<any>(LanguageContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: deleteStateMutate, isLoading: isDeleteStateLoading } = useMutation({
    mutationFn: () => deletePrivateSafebox(safebox?.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries('private_safeboxes');
      toast.success(i18n.t('messages.success.deleteSafebox'));
    },
  });

  return (
    <>
      <Popover
        placement={isRtl ? 'topRight' : 'topLeft'}
        zIndex={800}
        content={
          <Space direction={'vertical'}>
            <Button
              className={'p-0 black-text-color'}
              size={'small'}
              type={'dashed'}
              onClick={() => setEditBoxId(safebox?.id)}
              icon={<EditIcon width={20} height={20} />}
            >
              {i18n.t('safebox.changeQuantity')}
            </Button>
            <Button
              className={'p-0 black-text-color'}
              size={'small'}
              type={'dashed'}
              onClick={() => navigate(`/safebox/${safebox?.id}`)}
              icon={<HistoryIcon width={20} height={20} />}
            >
              {i18n.t('safebox.history')}
            </Button>
            <Button
              className={'p-0 black-text-color'}
              size={'small'}
              type={'dashed'}
              onClick={() => setIsModalOpen(true)}
              icon={<EditNameIcon width={20} height={20} />}
            >
              {i18n.t('safebox.rename')}
            </Button>
            <Popconfirm
              title={<p>{i18n.t('safebox.delete')}</p>}
              onConfirm={() => deleteStateMutate()}
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'bottom'}
              okButtonProps={{ size: 'small', type: 'primary' }}
              cancelButtonProps={{ size: 'small', type: 'default' }}
            >
              <Button
                className={'p-0 black-text-color'}
                size={'small'}
                type={'dashed'}
                loading={isDeleteStateLoading}
                icon={<DeleteIcon width={20} height={20} />}
              >
                {i18n.t('safebox.delete')}
              </Button>
            </Popconfirm>
          </Space>
        }
        trigger="click"
      >
        <Button className={'collapsed px-2'} type={'default'} icon={<SettingIcon width={28} />} />
      </Popover>
      <SafeboxModal
        safeboxId={safebox?.id}
        safeboxName={safebox?.name}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
