import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Col, Empty, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { NumericFormat } from 'react-number-format';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { i18n } from '@app/translations/i18n.config';
import { minTotalToShowMessage } from '@app/components/tables/safebox_table/constants';
import { SafeBoxTable } from '@app/components/tables/safebox_table';
import { getSafeboxes, updateSafeboxState } from '@app/api/safeboxes';
import { parseSafeboxState } from '@app/utils/calculations/calculations';

import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as HistoryIcon } from '@app/assets/icons/history-icon.svg';
import { useNavigate } from 'react-router-dom';

export const MainSafeboxes = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [editBoxId, setEditBoxId] = useState(null);

  const { status: safeboxesStatus, data: safeboxes } = useQuery({
    queryKey: ['safeboxes'],
    queryFn: () => getSafeboxes(),
  });

  const { mutate: saveStateMutate, isLoading: isSaveStateLoading } = useMutation({
    mutationFn: (data: any) => updateSafeboxState(data.id, data.payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries('safeboxes');
      setEditBoxId(null);
      toast.success(i18n.t('messages.success.safeboxUpdate'));
    },
  });

  const saveHandler = (id, tableData) => {
    const data = {
      id,
      payload: {
        state: parseSafeboxState(tableData),
      },
    };
    saveStateMutate(data);
  };

  return (
    <RQContent status={safeboxesStatus}>
      <>
        <Row gutter={24}>
          {Tools.isBlank(safeboxes) ? (
            <Empty className={'w-100 p-3'} description={i18n.t('messages.error.empty')} />
          ) : (
            safeboxes?.map((s) => (
              <Col lg={8} md={12} xs={24} className={'mb-3'} key={s.id}>
                <Card
                  bordered={false}
                  title={
                    <Space className={'w-100 justify-content-between flex-wrap'}>
                      <Typography.Title level={3}>{s.name}</Typography.Title>
                      {s.totalAmount < minTotalToShowMessage && (
                        <Tag color={'gold'}>
                          {i18n.t('refund.lessTotal')}
                          <NumericFormat displayType={'text'} value={minTotalToShowMessage} thousandSeparator={','} />
                        </Tag>
                      )}
                      {!editBoxId && (
                        <Space>
                          <Tooltip title={i18n.t('safebox.history')}>
                            <Button
                              type={'dashed'}
                              size={'small'}
                              className={'collapsed'}
                              onClick={() => navigate(`/safebox/${s.id}`)}
                              icon={<HistoryIcon width={21} height={21} />}
                            />
                          </Tooltip>
                          <Button
                            type={'dashed'}
                            size={'small'}
                            className={'collapsed'}
                            onClick={() => setEditBoxId(s.id)}
                            icon={<EditIcon width={20} height={20} />}
                          />
                        </Space>
                      )}
                    </Space>
                  }
                >
                  <SafeBoxTable
                    isLoading={isSaveStateLoading}
                    safebox={s}
                    isEditMode={editBoxId === s.id}
                    onDiscard={() => setEditBoxId(null)}
                    onSave={(data) => saveHandler(s.id, data)}
                  />
                </Card>
              </Col>
            ))
          )}
        </Row>
      </>
    </RQContent>
  );
};
