import { Settings } from '@app/config/settings';

const envName = 'production';
// const envName = 'development';

export class Env {
  static get(varName: string) {
    const set = Settings[envName] || {};
    return set[varName];
  }
}
