import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { ModalProps } from 'antd/es/modal';

import { i18n } from '@app/translations/i18n.config';
import { createPrivateSafebox, updatePrivateSafebox } from '@app/api/safeboxes';
import { toast } from 'react-toastify';

interface SafeboxModalProps extends ModalProps {
  safeboxName?: string;
  safeboxId?: string;
}

export const SafeboxModal: FC<SafeboxModalProps> = ({ safeboxName, safeboxId, ...props }) => {
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const [name, setName] = useState(safeboxName ? safeboxName : null);

  const { mutate: createMutate, isLoading: isCreateLoading } = useMutation({
    mutationFn: (data: any) => createPrivateSafebox(data),
    onSuccess: async () => {
      toast.success(i18n.t('messages.success.createSafebox'));
      await queryClient.invalidateQueries('private_safeboxes');
      setName(null);
      props.onCancel(null);
    },
  });

  const { mutate: updateMutate, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (data: any) => updatePrivateSafebox(safeboxId, data),
    onSuccess: async () => {
      toast.success(i18n.t('messages.success.safeboxUpdate'));
      await queryClient.invalidateQueries('private_safeboxes');
      props.onCancel(null);
    },
  });

  useEffect(() => {
    let timeoutId;
    if (props.open && inputRef.current) {
      timeoutId = setTimeout(() => inputRef.current.focus(), 0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.open, inputRef]);

  return (
    <Modal
      {...props}
      width={450}
      centered
      footer={false}
      title={<Typography.Title level={3}>{i18n.t('safebox.privateSafeboxes.addSafebox')}</Typography.Title>}
    >
      <Form layout={'vertical'} className={'p-2'}>
        <Form.Item label={i18n.t('safebox.privateSafeboxes.addSafeboxLabel')}>
          <Input
            ref={inputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onPressEnter={() => {
              if (name) {
                safeboxName ? updateMutate({ name }) : createMutate({ name });
              }
            }}
          />
        </Form.Item>

        <Button
          loading={isCreateLoading || isUpdateLoading}
          type={'primary'}
          disabled={!name}
          className={'w-50 mt-5 mx-auto'}
          onClick={() => (safeboxName ? updateMutate({ name }) : createMutate({ name }))}
        >
          {i18n.t('safebox.save')}
        </Button>
      </Form>
    </Modal>
  );
};
