import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';

import { Languages } from '@app/types/languages';
import { ThemeEnum } from '@app/types/theme';
import { ToastContainer } from 'react-toastify';
import { TOAST_CONFIG } from '@app/config/constants';

export const LanguageContext = React.createContext<{
  language: string;
  setLanguage: (language: string) => void;
  isRtl: boolean;
} | null>(null);

export const ThemeContext = React.createContext<{
  theme: string;
  setTheme: (theme: string) => void;
} | null>(null);

const GlobalProvider = ({ children }: any) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang'));
  const [theme, setTheme] = useState(localStorage.getItem('theme') || ThemeEnum.Light);

  const isRtl = language === Languages.AR;

  useEffect(() => {
    localStorage.setItem('theme', theme);
    if (theme === ThemeEnum.Dark) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [theme]);

  return (
    <LanguageContext.Provider key={language} value={{ language, setLanguage, isRtl }}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <ConfigProvider direction={isRtl ? 'rtl' : 'ltr'}>{children}</ConfigProvider>
        <ToastContainer {...TOAST_CONFIG} theme={theme} />
      </ThemeContext.Provider>
    </LanguageContext.Provider>
  );
};

export default GlobalProvider;
