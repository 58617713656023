import React, { FC, useContext, useMemo, useState } from 'react';
import { Button, Popconfirm, Popover, Space } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { LanguageContext, ThemeContext } from '@app/global_provider';
import { deletePreset, getPresetList, updateDefaultPresetType, updatePreset } from '@app/api/presets';
import { PresetModal } from '@app/components/modals/preset_modal';
import { useAuth } from '@app/auth/auth-context';
import { isRoleHasLimitToCreatePreset, isShowChangePresetType } from '@app/utils/permissions/permissions';
import { PresetTypeModal } from '@app/components/modals/preset_type_modal';
import { Tools } from '@app/utils/tools';
import { getPrivatePresetLength } from '@app/components/tables/request_table/search_filter_bar/utils';
import { ALLOWED_PRESET_TO_CREATE_LENGTH, colors } from '@app/config/constants';
import { ThemeEnum } from '@app/types/theme';

import { ReactComponent as CheckIcon } from '@app/assets/icons/ckeck-icon.svg';
import { ReactComponent as EditTypeIcon } from '@app/assets/icons/edit-type-icon.svg';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as SettingIcon } from '@app/assets/icons/setting-icon.svg';
import { useQueryState } from '@app/hooks/use-query-state';

interface PresetActionsProps {
  currentPreset: any;
  filters: any;
  isSaveDisabled: boolean;
  isPresetSelected: boolean;
  isOriginParamsUpdated: boolean;
  onSuccessCreated?: (presetId: string) => void;
  resetFilters?: () => void;
}

export const PresetActions: FC<PresetActionsProps> = ({
  currentPreset,
  filters,
  isSaveDisabled,
  isPresetSelected,
  isOriginParamsUpdated,
  onSuccessCreated,
  resetFilters,
}) => {
  const { isRtl } = useContext<any>(LanguageContext);
  const queryClient = useQueryClient();
  const { userRoles } = useAuth();
  const { theme } = React.useContext<any>(ThemeContext);
  const [urlParams, setUrlParams] = useQueryState();

  const [isPresetModalOpen, setIsPresetModalOpen] = useState(false);
  const [isPresetTypeModalOpen, setIsPresetTypeModalOpen] = useState(false);
  const [isPresetChangeNameMode, setIsPresetChangeNameMode] = useState(false);

  const { data: presetList, isLoading: isPresetListLoading } = useQuery({
    queryKey: ['preset_list'],
    queryFn: () => getPresetList(),
  });

  const { mutate: deletePresetMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: () => deletePreset(currentPreset?.id),
    onSuccess: async () => {
      const { search, presetId, ...otherParams } = urlParams;
      await queryClient.invalidateQueries('preset_list');

      if (presetId === currentPreset?.id) {
        const resetParams = Object.keys(otherParams).reduce((acc, key) => {
          acc[key] = null;
          return acc;
        }, {});

        setUrlParams({ ...resetParams, presetId: null });
      }

      if (resetFilters) {
        resetFilters();
      }

      toast.success(i18n.t('messages.success.deletePreset'));
    },
  });

  const { mutate: updatePresetMutate, isLoading: isUpdatePresetLoading } = useMutation({
    mutationFn: (data: any) => updatePreset(currentPreset?.id, data),
    onSuccess: async () => {
      toast.success(i18n.t('messages.success.updatePreset'));
      await Promise.all([queryClient.invalidateQueries('preset_list'), queryClient.invalidateQueries('preset')]);
    },
  });

  const { mutate: updatePresetDefaultTypeMutate, isLoading: isUpdatePresetDefaultTypeLoading } = useMutation({
    mutationFn: (data: boolean) => updateDefaultPresetType(currentPreset?.id, data),
    onSuccess: async () => {
      if (currentPreset?.isDefault) {
        toast.success(i18n.t('messages.success.updatePresetToPrivate', { name: currentPreset?.name }));
      } else {
        toast.success(i18n.t('messages.success.updatePresetToDefault', { name: currentPreset?.name }));
      }
      await Promise.all([queryClient.invalidateQueries('preset_list'), queryClient.invalidateQueries('preset')]);
    },
  });

  const isShowLimit = useMemo(() => {
    if (Tools.isPresent(presetList) && isRoleHasLimitToCreatePreset(userRoles)) {
      return getPrivatePresetLength(presetList) >= ALLOWED_PRESET_TO_CREATE_LENGTH;
    } else {
      return false;
    }
  }, [presetList]);

  const updatePresetHandler = () => {
    const payload = {
      name: currentPreset?.name,
      metadata: filters,
    };
    updatePresetMutate(payload);
  };

  const isChangeTypeToGlobalAllowed = isShowChangePresetType(userRoles);

  return (
    <Space>
      {isPresetSelected && (isChangeTypeToGlobalAllowed || !currentPreset?.isGlobal) && (
        <Popover
          placement={isRtl ? 'topRight' : 'topLeft'}
          content={
            <Space direction={'vertical'}>
              {!currentPreset?.isGlobal && (
                <Button
                  loading={isUpdatePresetDefaultTypeLoading}
                  icon={<CheckIcon fill={theme === ThemeEnum.Light ? colors.black : colors.white} />}
                  className={'p-0 black-text-color'}
                  size={'small'}
                  type={'dashed'}
                  onClick={() => updatePresetDefaultTypeMutate(!currentPreset?.isDefault)}
                >
                  {currentPreset?.isDefault
                    ? i18n.t('searchFilterBar.presetActions.unsetAsDefault')
                    : i18n.t('searchFilterBar.presetActions.setAsDefault')}
                </Button>
              )}
              {isChangeTypeToGlobalAllowed && (
                <Button
                  icon={<EditTypeIcon stroke={theme === ThemeEnum.Light ? colors.black : colors.white} />}
                  className={'p-0 black-text-color'}
                  size={'small'}
                  type={'dashed'}
                  onClick={() => setIsPresetTypeModalOpen(true)}
                >
                  {i18n.t('searchFilterBar.presetActions.changeType')}
                </Button>
              )}
              {(isChangeTypeToGlobalAllowed || currentPreset?.isPrivate) && (
                <Button
                  icon={<EditIcon fill={theme === ThemeEnum.Light ? colors.black : colors.white} />}
                  className={'p-0 black-text-color'}
                  size={'small'}
                  type={'dashed'}
                  onClick={() => {
                    setIsPresetModalOpen(true);
                    setIsPresetChangeNameMode(true);
                  }}
                >
                  {i18n.t('searchFilterBar.presetActions.rename')}
                </Button>
              )}
              {(isChangeTypeToGlobalAllowed || currentPreset?.isPrivate) && (
                <Popconfirm
                  title={<p>{i18n.t('searchFilterBar.presetActions.removeConfirmTitle')}</p>}
                  className={'danger'}
                  icon={null}
                  okText={i18n.t('searchFilterBar.presetActions.confirmRemoveButton')}
                  cancelText={i18n.t('searchFilterBar.presetActions.cancel')}
                  placement={isRtl ? 'right' : 'left'}
                  onConfirm={() => deletePresetMutate()}
                  okButtonProps={{ size: 'small', type: 'primary' }}
                  cancelButtonProps={{ size: 'small', type: 'default' }}
                >
                  <Button
                    loading={isDeleteLoading}
                    icon={<DeleteIcon />}
                    className={'p-0 black-text-color'}
                    size={'small'}
                    type={'dashed'}
                  >
                    {i18n.t('searchFilterBar.presetActions.remove')}
                  </Button>
                </Popconfirm>
              )}
            </Space>
          }
          trigger="click"
        >
          <Button className={'collapsed px-2'} type={'default'} icon={<SettingIcon width={28} />} />
        </Popover>
      )}
      <Popover
        placement={isRtl ? 'topRight' : 'topLeft'}
        overlayClassName={
          (isSaveDisabled || (isPresetSelected && !isOriginParamsUpdated) || (!isPresetSelected && isShowLimit)) &&
          'd-none'
        }
        content={
          <Space direction={'vertical'}>
            {(!isSaveDisabled || (isPresetSelected && isOriginParamsUpdated)) && !isShowLimit && (
              <Button
                className={'p-0 black-text-color'}
                size={'small'}
                type={'dashed'}
                onClick={() => {
                  setIsPresetChangeNameMode(false);
                  setIsPresetModalOpen(true);
                }}
              >
                {i18n.t('searchFilterBar.presetActions.saveAsNew')}
              </Button>
            )}
            {isPresetSelected && (isChangeTypeToGlobalAllowed || currentPreset?.isPrivate) && (
              <Popconfirm
                title={<p>{i18n.t('searchFilterBar.presetActions.updateConfirmTitle')}</p>}
                className={'danger'}
                icon={null}
                okText={i18n.t('searchFilterBar.presetActions.confirmUpdateButton')}
                cancelText={i18n.t('searchFilterBar.presetActions.cancel')}
                placement={isRtl ? 'right' : 'left'}
                onConfirm={() => updatePresetHandler()}
                okButtonProps={{ size: 'small', type: 'primary' }}
                cancelButtonProps={{ size: 'small', type: 'default' }}
              >
                <Button className={'p-0 black-text-color'} size={'small'} type={'dashed'}>
                  {i18n.t('searchFilterBar.presetActions.update')}
                </Button>
              </Popconfirm>
            )}
          </Space>
        }
        trigger="click"
      >
        <Button
          disabled={
            isSaveDisabled || (isPresetSelected && !isOriginParamsUpdated) || (!isPresetSelected && isShowLimit)
          }
          loading={isUpdatePresetLoading}
          type={'default'}
        >
          {i18n.t('searchFilterBar.presetActions.savePreset')}
        </Button>
      </Popover>
      <PresetModal
        key={`preset-modal-status-${isPresetModalOpen}`}
        open={isPresetModalOpen}
        onSuccessCreated={onSuccessCreated}
        presetData={isPresetChangeNameMode ? currentPreset : null}
        onCancel={() => {
          setIsPresetModalOpen(false);
          setIsPresetChangeNameMode(false);
        }}
        filters={filters}
      />
      <PresetTypeModal
        key={`preset-type-modal-status${isPresetTypeModalOpen}`}
        open={isPresetTypeModalOpen}
        onCancel={() => setIsPresetTypeModalOpen(false)}
        presetData={currentPreset}
      />
    </Space>
  );
};
