import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Table, Tag } from 'antd';
import dayjs from 'dayjs';

import { RQContent } from '@app/components/rq_content';
import { getSafeboxHistory } from '@app/api/safeboxes';
import { i18n } from '@app/translations/i18n.config';
import { Tools } from '@app/utils/tools';
import { DATE_FORMAT, DEFAULT_REQUESTS_PARAMS, EMPTY_VALUE_REPRESENTATION, TIME_FORMAT } from '@app/config/constants';
import { SafeboxOperationTypeEnum } from '@app/types';
import { useAuth } from '@app/auth/auth-context';
import { isShowRequestLinkInSafeboxHistory } from '@app/utils/permissions/permissions';

export const SafeboxHistoryTable = () => {
  const { userRoles } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [params, setParams] = useState(DEFAULT_REQUESTS_PARAMS);

  const { status, data: historyData } = useQuery({
    queryKey: ['safebox_history', id, params],
    queryFn: () => getSafeboxHistory(id, params),
  });

  const handleTableChange = (pagination) => {
    let updatedParams = { ...DEFAULT_REQUESTS_PARAMS };

    if (pagination) {
      updatedParams.page = pagination.current;
      updatedParams.per_page = pagination.pageSize;
      setParams(updatedParams);
    }
  };

  const columns = [
    {
      title: i18n.t('safebox.historyTable.date'),
      dataIndex: 'date',
      key: 'date',
      showSorterTooltip: false,
      render: (record) => dayjs(record).format(DATE_FORMAT),
    },
    {
      title: i18n.t('safebox.historyTable.time'),
      dataIndex: 'date',
      key: 'date',
      showSorterTooltip: false,
      render: (record) => dayjs(record).format(TIME_FORMAT),
    },
    {
      title: i18n.t('safebox.historyTable.name'),
      dataIndex: 'creator',
      key: 'creator',
      showSorterTooltip: false,
      render: (record) => (Tools.isPresent(record) ? record?.name : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: i18n.t('safebox.historyTable.status'),
      dataIndex: 'recordType',
      key: 'recordType',
      showSorterTooltip: false,
      render: (record) =>
        record === SafeboxOperationTypeEnum.Payment ? (
          <Tag color={'volcano'}>{i18n.t('safebox.status.payment')}</Tag>
        ) : (
          <Tag color={'volcano'}>{i18n.t('safebox.status.cashDeposit')}</Tag>
        ),
    },
    {
      title: i18n.t('safebox.historyTable.safeboxFullAmount'),
      dataIndex: 'safeboxAmount',
      key: 'safeboxAmount',
      render: (record) => (
        <div style={{ direction: 'ltr' }}>{Tools.isPresent(record) ? record : EMPTY_VALUE_REPRESENTATION}</div>
      ),
      showSorterTooltip: false,
    },
    {
      title: i18n.t('safebox.historyTable.amount'),
      dataIndex: 'recordAmount',
      key: 'recordAmount',
      render: (record) => (
        <div style={{ direction: 'ltr' }}>{Tools.isPresent(record) ? record : EMPTY_VALUE_REPRESENTATION}</div>
      ),
      showSorterTooltip: false,
    },
    {
      dataIndex: 'record',
      key: 'record',
      render: (record) =>
        Tools.isPresent(record?.refundRequestId) &&
        isShowRequestLinkInSafeboxHistory(userRoles, record?.refundRequest?.status?.key) && (
          <Button size={'small'} type={'dashed'} onClick={() => navigate(`/request/${record?.refundRequestId}`)}>
            {i18n.t('request.view')}
          </Button>
        ),
      showSorterTooltip: false,
    },
  ];

  return (
    <RQContent status={status}>
      <Table
        dataSource={historyData?.data}
        columns={columns}
        pagination={{
          current: historyData?.meta?.currentPage,
          pageSize: historyData?.meta?.perPage,
          total: historyData?.meta?.total,
          size: 'small',
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </RQContent>
  );
};
