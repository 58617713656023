import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Col, Row } from 'antd';
import { AppHeader } from '@app/components/app_header';
import { i18n } from '@app/translations/i18n.config';
import { SafeboxHistoryTable } from '@app/components/tables/safebox_history_table';
import { useQuery } from 'react-query';
import { getSafebox } from '@app/api/safeboxes';
import { useParams } from 'react-router-dom';
import { RQContent } from '@app/components/rq_content';

export const SafeBoxHistory = () => {
  const { id } = useParams();

  const { status, data: safeboxData } = useQuery({
    queryKey: ['safebox', id],
    queryFn: () => getSafebox(id),
  });

  return (
    <>
      <RQContent status={status}>
        <AppHeader pageTitle={i18n.t('safebox.historyTitle', { name: safeboxData?.name })} />
      </RQContent>
      <Content className={'mx-2 mt-2 px-4 py-2'}>
        <Row>
          <Col span={24}>
            <Content className={'sub-content-white p-2 mt-3'}>
              <SafeboxHistoryTable />
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

