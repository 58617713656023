import React from 'react';

import { LanguageContext, ThemeContext } from '@app/global_provider';
import { ThemeEnum } from '@app/types/theme';
import { colors } from '@app/config/constants';

import { ReactComponent as LogoIcon } from '@app/assets/icons/refund_logo.svg';
import { ReactComponent as LogoTextIcon } from '@app/assets/icons/logo_text.svg';
import { ReactComponent as LogoTextARIcon } from '@app/assets/icons/logo_text_ar.svg';

export const Logo = ({ isCollapsed }) => {
  const { isRtl } = React.useContext<any>(LanguageContext);
  const { theme } = React.useContext<any>(ThemeContext);

  return (
    <div className={'d-flex align-items-start mb-5'}>
      <LogoIcon />
      {!isCollapsed &&
        (isRtl ? (
          <LogoTextARIcon fill={theme === ThemeEnum.Light ? colors.black : colors.white} className={'mx-2'} />
        ) : (
          <LogoTextIcon fill={theme === ThemeEnum.Light ? colors.black : colors.white} className={'mx-2'} />
        ))}
    </div>
  );
};
