import React from 'react';
import { Content } from 'antd/es/layout/layout';

import { AppHeader } from '@app/components/app_header';
import { i18n } from '@app/translations/i18n.config';
import { MainSafeboxes, PrivateSafeboxes } from '@app/components/safeboxes';

export const SafeBox = () => {
  return (
    <>
      <AppHeader pageTitle={i18n.t('pageTitles.safeboxManagement')} />

      <Content className={'mx-2 mt-2 p-4'}>
        <MainSafeboxes />
        <PrivateSafeboxes />
      </Content>
    </>
  );
};
