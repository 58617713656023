import axiosInstance from '@app/config/api/config';

export async function getSafeboxes() {
  const res = await axiosInstance.get('safeboxes');

  return res.data;
}

export async function getSafebox(safeboxId: string) {
  const res = await axiosInstance.get(`safeboxes/${encodeURIComponent(safeboxId)}`);

  return res.data;
}

export async function updateSafeboxState(safeboxId: string, payload) {
  const res = await axiosInstance.post(`safeboxes/${encodeURIComponent(safeboxId)}/state`, payload);

  return res.data;
}

export async function getPrivateSafeboxes() {
  const res = await axiosInstance.get('safeboxes/private');

  return res.data;
}

export async function createPrivateSafebox(payload) {
  const res = await axiosInstance.post('safeboxes', payload);

  return res.data;
}

export async function updatePrivateSafebox(safeboxId: string, payload) {
  const res = await axiosInstance.put(`safeboxes/${encodeURIComponent(safeboxId)}`, payload);

  return res.data;
}

export async function deletePrivateSafebox(safeboxId: string) {
  const res = await axiosInstance.delete(`safeboxes/${encodeURIComponent(safeboxId)}`);

  return res.data;
}

export async function getSafeboxHistory(safeboxId: string, params) {
  const res = await axiosInstance.get(`safeboxes/${encodeURIComponent(safeboxId)}/history`, { params });

  return res.data;
}

