import React, { FC } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Col, Row, Space, Switch, Typography } from 'antd';

import { LanguageContext, ThemeContext } from '@app/global_provider';
import { i18n } from '@app/translations/i18n.config';
import { Languages } from '@app/types/languages';
import { ThemeEnum } from '@app/types/theme';

interface AppHeaderProps {
  pageTitle: string;
}

export const AppHeader: FC<AppHeaderProps> = ({ pageTitle }) => {
  const { language, setLanguage } = React.useContext<any>(LanguageContext);
  const { theme, setTheme } = React.useContext<any>(ThemeContext);

  const languageChangeHandler = (isActive: boolean) => {
    const activeLanguage = isActive ? Languages.AR : Languages.EN;

    setLanguage(activeLanguage);
    i18n.locale = activeLanguage;
    localStorage.setItem('lang', activeLanguage);
  };

  const themeChangeHandler = (isActive: boolean) => {
    const activeTheme = isActive ? ThemeEnum.Dark : ThemeEnum.Light;
    setTheme(activeTheme);
  };

  return (
    <Header className={'py-2 px-4 mx-2'}>
      <Row className={'justify-content-between align-items-center'}>
        <Col span={12}>
          <Typography.Title level={2}>{pageTitle}</Typography.Title>
        </Col>
        <Col span={4} className={'d-flex justify-content-end'}>
          <Space direction={'horizontal'}>
            <Switch
              className={'mx-2'}
              onChange={languageChangeHandler}
              checkedChildren={Languages.AR}
              unCheckedChildren={Languages.EN}
              checked={language === Languages.AR}
            />
            <Switch
              onChange={themeChangeHandler}
              checkedChildren={ThemeEnum.Dark}
              unCheckedChildren={ThemeEnum.Light}
              checked={theme === ThemeEnum.Dark}
            />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};
