import React, { FC, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Select, Tag } from 'antd';
import { useQuery } from 'react-query';

import { Tools } from '@app/utils/tools';
import {
  getPrivatePresetLength,
  transformPresetsToSelectOptions,
} from '@app/components/tables/request_table/search_filter_bar/utils';
import { getPreset, getPresetList } from '@app/api/presets';
import { i18n } from '@app/translations/i18n.config';
import { useQueryState } from '@app/hooks/use-query-state';
import { ALLOWED_PRESET_TO_CREATE_LENGTH } from '@app/config/constants';
import { isRoleHasLimitToCreatePreset } from '@app/utils/permissions/permissions';
import { useAuth } from '@app/auth/auth-context';

interface PresetSelectProps {
  ref?: any;
  filters: any;
  updatePresetParams: (data: any, presetId?: string) => void;
  setPresetData?: (data: any) => void;
  allowClear?: boolean;
  className?: string;
}

const PresetSelect: FC<PresetSelectProps> = forwardRef(
  ({ filters, updatePresetParams, allowClear, className, setPresetData }, ref) => {
    const { userRoles } = useAuth();
    const [urlParams, setUrlParams] = useQueryState();

    const [currentPresetId, setCurrentPresetId] = useState(() => {
      const { presetId } = urlParams;
      return presetId;
    });

    const { data: presetList, isLoading: isPresetListLoading } = useQuery({
      queryKey: ['preset_list'],
      queryFn: () => getPresetList(),
    });

    const { data: preset, isLoading: isPresetLoading } = useQuery({
      queryKey: ['preset', currentPresetId],
      queryFn: () => getPreset(currentPresetId),
      enabled: Tools.isPresent(currentPresetId),
    });

    const presetOptions = useMemo(() => {
      if (Tools.isPresent(presetList)) {
        return transformPresetsToSelectOptions(presetList);
      } else {
        return null;
      }
    }, [presetList]);

    const isShowLimit = useMemo(() => {
      if (Tools.isPresent(presetList) && isRoleHasLimitToCreatePreset(userRoles)) {
        return getPrivatePresetLength(presetList) >= ALLOWED_PRESET_TO_CREATE_LENGTH;
      } else {
        return false;
      }
    }, [presetList]);

    const updateParamsHandler = (oldData, newData) => {
      const result = { ...oldData };

      for (const key in oldData) {
        if (!newData.hasOwnProperty(key)) {
          result[key] = null;
        } else {
          result[key] = newData[key];
        }
      }

      for (const key in newData) {
        if (!filters.hasOwnProperty(key)) {
          result[key] = newData[key];
        }
      }

      updatePresetParams(result, preset?.id);
      return result;
    };

    useImperativeHandle(ref, () => ({
      setCurrentPresetId,
    }));

    useEffect(() => {
      if (Tools.isPresent(preset)) {
        setPresetData && setPresetData(preset);
        updateParamsHandler(filters, preset?.metadata);
      }
    }, [preset]);

    return (
      <>
        <Select
          options={presetOptions}
          placeholder={i18n.t('searchFilterBar.preset.placeholder')}
          className={`w-100 ${Tools.isPresent(className) ? className : ''}`}
          loading={isPresetListLoading || isPresetLoading}
          allowClear={allowClear}
          value={currentPresetId}
          onClear={() => {
            const { search, ...otherParams } = urlParams;
            setUrlParams(updateParamsHandler(otherParams, { search }));
          }}
          onChange={setCurrentPresetId}
        />
        {!allowClear && isShowLimit && (
          <Tag color={'volcano'} className={'my-2'}>
            {i18n.t('searchFilterBar.preset.lengthMessage')}
          </Tag>
        )}
      </>
    );
  },
);

export default PresetSelect;
