import React, { FC, useState } from 'react';
import { Button, Modal, Typography, Form, Radio } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { updateGlobalPresetType } from '@app/api/presets';

interface PresetTypeModalProps extends ModalProps {
  presetData: any;
}

export const PresetTypeModal: FC<PresetTypeModalProps> = ({ presetData, ...props }) => {
  const queryClient = useQueryClient();

  const [isGlobal, setIsGlobal] = useState(presetData?.isGlobal);

  const { mutate: updatePresetGlobalTypeMutate, isLoading: isUpdatePresetGlobalTypeLoading } = useMutation({
    mutationFn: (data: boolean) => updateGlobalPresetType(presetData?.id, data),
    onSuccess: async () => {
      if (isGlobal) {
        toast.success(i18n.t('messages.success.updatePresetToGlobal', { name: presetData?.name }));
      } else {
        toast.success(i18n.t('messages.success.updatePresetToPrivate', { name: presetData?.name }));
      }
      await Promise.all([queryClient.invalidateQueries('preset_list'), queryClient.invalidateQueries('preset')]);
      props.onCancel(null);
    },
  });

  return (
    <Modal
      {...props}
      width={450}
      centered
      footer={false}
      title={
        <Typography.Title level={3}>{i18n.t('searchFilterBar.presetActions.modal.changeTypeTitle')}</Typography.Title>
      }
    >
      <Form layout={'vertical'} className={'p-2'}>
        <Form.Item label={i18n.t('searchFilterBar.presetActions.modal.typeLabel')}>
          <Radio.Group onChange={(e) => setIsGlobal(e.target.value)} value={isGlobal}>
            <Radio value={true}>{i18n.t('searchFilterBar.presetActions.modal.typeGlobalLabel')}</Radio>
            <Radio value={false}>{i18n.t('searchFilterBar.presetActions.modal.typePrivateLabel')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Button
          loading={isUpdatePresetGlobalTypeLoading}
          type={'primary'}
          disabled={isGlobal === presetData?.isGlobal}
          className={'w-50 mt-5 mx-auto'}
          onClick={() => updatePresetGlobalTypeMutate(isGlobal)}
        >
          {i18n.t('searchFilterBar.presetActions.save')}
        </Button>
      </Form>
    </Modal>
  );
};
