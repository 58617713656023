import {
  RefundRequestStatusesEnum,
  Request,
  RequestStatusType,
  StatusCategoryEnum,
  StatusEntity,
  UserRolesEnum,
} from '@app/types';

export const isEditRequestActionsAllowed = (
  userRoles: Array<string>,
  status: StatusEntity,
  isOwner: boolean,
): boolean => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  if (isAppAdmin) {
    switch (status.key) {
      case RefundRequestStatusesEnum.Done:
      case RefundRequestStatusesEnum.Cancelled:
        return false;
      default:
        return true;
    }
  }

  if (status.statusCategory === StatusCategoryEnum.ToDo && status.key !== RefundRequestStatusesEnum.Draft) {
    return false;
  }

  switch (status.key) {
    case RefundRequestStatusesEnum.Draft:
    case RefundRequestStatusesEnum.ModifyRequestInProgress:
      return isUser || isOwner || isAdmin;
    case RefundRequestStatusesEnum.InProcess:
      return isAdmin && !isOwner;
    case RefundRequestStatusesEnum.ReturnedForVerification:
      return isAdmin && !isOwner;
    default:
      return false;
  }
};

export const isCancelledRequestsAllowed = (userRoles: Array<string>) => {
  return userRoles.includes(UserRolesEnum.AppAdmin);
};

export const isAddCommentActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isUser || isAdmin || isAuditor || isPlanning || isAppAdmin;
};

export const isShowReceiptCategory = (userRoles: Array<string>, status: RequestStatusType) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  const allowedStatuses = [RefundRequestStatusesEnum.RefundPaid, RefundRequestStatusesEnum.ApprovedByPlanningTeam];

  return (isPlanning || isAppAdmin) && allowedStatuses.includes(status?.key);
};

export const isEditReceiptCategoryAllowed = (userRoles: Array<string>, status: RequestStatusType) => {
  return isShowReceiptCategory(userRoles, status) && status?.statusCategory === StatusCategoryEnum.InProgress;
};

export const isShowDownloadReceiptCheckboxes = (userRoles: Array<string>) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isPlanning || isAppAdmin;
};

export const isShowVerifyReceiptCheckboxes = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const forbiddenStatuses = [
    RefundRequestStatusesEnum.Draft,
    RefundRequestStatusesEnum.RefundPaid,
    RefundRequestStatusesEnum.Done,
  ];

  if (forbiddenStatuses.includes(statusKey)) {
    return false;
  }

  return !isUser;
};

export const isChangeCategoryForAuditorAllowed = (
  userRoles: Array<string>,
  statusKey: RefundRequestStatusesEnum | string,
) => {
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.WaitingForApprovalByAuditor;

  return (isAuditor || isAppAdmin) && isAllowedStatus;
};

export const isAddRequestActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isUser || isAdmin || isAppAdmin;
};

export const isSafeBoxesAllowed = (userRoles: Array<string>) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isPlanning || isAppAdmin;
};

export const isRefundAllowed = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum | string) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.RefundReadyForPayment;

  return (isAdmin || isAppAdmin) && isAllowedStatus;
};

export const isStayOnRequestPage = (statusCategory: StatusCategoryEnum | string) => {
  return statusCategory === StatusCategoryEnum.ToDo;
};

export const isShowStartButton = (userRoles: Array<string>, request: Request) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  if (request?.status?.statusCategory !== StatusCategoryEnum.ToDo) {
    return false;
  }

  if (isAppAdmin && request?.status?.statusCategory === StatusCategoryEnum.ToDo) {
    return true;
  }

  const roleConditions = {
    [UserRolesEnum.User]: [RefundRequestStatusesEnum.ModifyRequest],
    [UserRolesEnum.Admin]: [
      RefundRequestStatusesEnum.ModifyRequest,
      RefundRequestStatusesEnum.NotReceived,
      RefundRequestStatusesEnum.ReturnedForVerification,
      RefundRequestStatusesEnum.RequestReSubmitted,
      RefundRequestStatusesEnum.ApprovedByAuditor,
    ],
    [UserRolesEnum.Auditor]: [RefundRequestStatusesEnum.ApprovedByAdmin],
    [UserRolesEnum.Planning]: [RefundRequestStatusesEnum.RefundPaid],
    [UserRolesEnum.RefundTeam]: [
      RefundRequestStatusesEnum.WaitingForApprovalByRefundTeam,
      //
    ],
  };

  return userRoles.some((role) => roleConditions[role]?.includes(request.status.key));
};

export const isShowTotalAmountPaid = (userRoles: Array<string>) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isAdmin || isPlanning || isAppAdmin;
};

export const isShowUpdatesHistory = (userRoles: Array<string>) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isAppAdmin;
};

export const isShowChangePresetType = (userRoles: Array<string>) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);

  return isAppAdmin || isAdmin;
};

export const isRoleHasLimitToCreatePreset = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isRefundTeam = userRoles.includes(UserRolesEnum.RefundTeam);

  return isUser || isAuditor || isPlanning || isRefundTeam;
};

export const isPrivateSafeboxesAllowed = (userRoles: Array<string>) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  return isAppAdmin;
};

export const isShowRequestLinkInSafeboxHistory = (
  userRoles: Array<string>,
  statusKey: RefundRequestStatusesEnum | string,
) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  if (isPlanning) {
    return statusKey === RefundRequestStatusesEnum.ApprovedByPlanningTeam;
  }

  return isAppAdmin;
};

export const isShowReSyncRequestButton = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum | string) => {
  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isStatusAllowed = statusKey !== RefundRequestStatusesEnum.Draft;

  return (isAppAdmin || isAdmin) && isStatusAllowed;
};
