export type Safebox = {
  id: string;
  key: string;
  name: string;
  state: Object;
  createdAt: string;
  updatedAt: string;
  totalAmount: number;
};

export enum SafeboxOperationTypeEnum {
  Payment = 'payment',
  CashDeposit = 'cash_deposit',
}
