import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Tag } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { useQueryState } from '@app/hooks/use-query-state';
import { FilterDrawer } from '@app/components/tables/request_table/search_filter_bar/filter-drawer';
import { i18n } from '@app/translations/i18n.config';
import { Tools } from '@app/utils/tools';
import PresetSelect from '@app/components/tables/request_table/search_filter_bar/preset-select';
import { FilterTags } from '@app/components/tables/request_table/search_filter_bar/filter-tags';
import { ThemeContext } from '@app/global_provider';
import { ThemeEnum } from '@app/types/theme';
import { colors } from '@app/config/constants';

import { ReactComponent as SearchIcon } from '@app/assets/icons/input-suffix.svg';
import { ReactComponent as FilterIcon } from '@app/assets/icons/filter-icon.svg';
import { ReactComponent as AppliedFilterIcon } from '@app/assets/icons/applied-filter-icon.svg';

export const SearchFilterBar = () => {
  const { theme } = React.useContext<any>(ThemeContext);

  const [urlParams, setUrlParams] = useQueryState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const updateUrlParamsHandler = (params) => {
    setUrlParams({ ...urlParams, ...params });
  };

  const debounced = useDebouncedCallback(
    (updatedParams) => {
      updateUrlParamsHandler(updatedParams);
    },
    1000,
    { maxWait: 3000 },
  );

  useEffect(() => {
    if (urlParams) {
      const { search, ...otherParams } = urlParams;
      setIsFilterApplied(Object.values(otherParams).some((p) => Tools.isPresent(p)));
    }
  }, [urlParams]);

  return (
    <>
      <Row className={'p-2'} gutter={25}>
        <Col span={5}>
          <Input
            defaultValue={urlParams.search}
            allowClear
            style={{ width: '100%' }}
            className={'with-suffix'}
            prefix={<SearchIcon fill={theme === ThemeEnum.Light ? colors.black : colors.white} />}
            placeholder={i18n.t('searchFilterBar.searchPlaceholder')}
            onChange={(e) => debounced({ search: e.target.value })}
          />
        </Col>
        <Col span={5}>
          <PresetSelect
            key={`selected-preset-status-${isDrawerOpen}`}
            filters={urlParams}
            allowClear
            updatePresetParams={(params, presetId) => {
              const { search } = urlParams;
              setUrlParams({ ...params, presetId, search });
            }}
          />
        </Col>
        <Col span={2}>
          <Button
            size={'small'}
            className={'border-0'}
            type={'default'}
            icon={isFilterApplied ? <AppliedFilterIcon /> : <FilterIcon />}
            onClick={() => setIsDrawerOpen(true)}
          >
            {i18n.t('searchFilterBar.buttons.openFilter')}
          </Button>
        </Col>
        <Col span={12} className={'d-flex align-items-center flex-wrap gap-1'}>
          <FilterTags />
        </Col>
      </Row>
      <FilterDrawer
        key={`drawer-status-${isDrawerOpen}`}
        isDrawerOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
