import React, {useState} from 'react';
import {Button, Card, Col, Divider, Empty, Row, Space, Tag, Typography} from 'antd';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {NumericFormat} from 'react-number-format';

import {i18n} from '@app/translations/i18n.config';
import {SafeBoxTable} from '@app/components/tables/safebox_table';
import {getPrivateSafeboxes, updateSafeboxState} from '@app/api/safeboxes';
import {Tools} from '@app/utils/tools';
import {minTotalToShowMessage} from '@app/components/tables/safebox_table/constants';
import {RQContent} from '@app/components/rq_content';
import {parseSafeboxState} from '@app/utils/calculations/calculations';
import {SafeboxModal} from '@app/components/modals/safebox_modal/safebox-modal';
import {SafeboxActions} from '@app/components/safeboxes/safebox-actions';

import {ReactComponent as PlusIcon} from '@app/assets/icons/plus.svg';
import {isPrivateSafeboxesAllowed} from "@app/utils/permissions/permissions";
import {useAuth} from "@app/auth/auth-context";

export const PrivateSafeboxes = () => {
  const queryClient = useQueryClient();
  const {userRoles} = useAuth();

  const [editBoxId, setEditBoxId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {status: privateSafeboxesStatus, data: privateSafeboxes} = useQuery({
    queryKey: ['private_safeboxes'],
    queryFn: () => getPrivateSafeboxes(),
  });

  const {mutate: saveStateMutate, isLoading: isSaveStateLoading} = useMutation({
    mutationFn: (data: any) => updateSafeboxState(data.id, data.payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries('private_safeboxes');
      setEditBoxId(null);
      toast.success(i18n.t('messages.success.safeboxUpdate'));
    },
  });

  const saveHandler = (id, tableData) => {
    const data = {
      id,
      payload: {
        state: parseSafeboxState(tableData),
      },
    };
    saveStateMutate(data);
  };

  const isAllowed = isPrivateSafeboxesAllowed(userRoles);

  return (
    <>
      {isAllowed && (
        <Row gutter={24} className={'mt-4'}>
          <Col span={24}>
            <Space className={'w-100 justify-content-between'}>
              <Typography.Title level={3}>{i18n.t('safebox.privateSafeboxes.title')}</Typography.Title>
              <Button icon={<PlusIcon/>} size={'small'} type={'default'} onClick={() => setIsModalOpen(true)}>
                {i18n.t('safebox.privateSafeboxes.addSafebox')}
              </Button>
            </Space>
            <Divider/>
          </Col>
          <RQContent status={privateSafeboxesStatus}>
            <Col span={24}>
              <Row gutter={24}>
                {Tools.isBlank(privateSafeboxes) ? (
                  <Empty className={'w-100 p-3'} description={i18n.t('messages.error.empty')}/>
                ) : (
                  privateSafeboxes?.map((s) => (
                    <Col lg={8} md={12} xs={24} className={'mb-3'} key={s.id}>
                      <Card
                        bordered={false}
                        title={
                          <Space className={'w-100 justify-content-between flex-wrap'}>
                            <Typography.Title level={3}>{s.name}</Typography.Title>
                            {s.totalAmount < minTotalToShowMessage && (
                              <Tag color={'gold'}>
                                {i18n.t('refund.lessTotal')}
                                <NumericFormat displayType={'text'} value={minTotalToShowMessage}
                                               thousandSeparator={','}/>
                              </Tag>
                            )}
                            {!editBoxId && <SafeboxActions safebox={s} setEditBoxId={setEditBoxId}/>}
                          </Space>
                        }
                      >
                        <SafeBoxTable
                          isLoading={isSaveStateLoading}
                          safebox={s}
                          isEditMode={editBoxId === s.id}
                          onDiscard={() => setEditBoxId(null)}
                          onSave={(data) => saveHandler(s.id, data)}
                        />
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
              <SafeboxModal open={isModalOpen} onCancel={() => setIsModalOpen(false)}/>
            </Col>
          </RQContent>
        </Row>
      )}
    </>
  );
};

