import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from '@app/router';
import GlobalProvider from '@app/global_provider';
import { AuthProvider } from '@app/auth/auth-provider';
import ReactQueryConfiguration from '@app/config/api/react-query-configuration';

export function App() {
  return (
    <div className="App">
      <ReactQueryConfiguration>
        <AuthProvider>
          <GlobalProvider>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </GlobalProvider>
        </AuthProvider>
      </ReactQueryConfiguration>
    </div>
  );
}
