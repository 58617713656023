import React, { FC, useRef, useState } from 'react';
import { Button, Input, Modal, Typography, Form } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { createPreset, updatePreset } from '@app/api/presets';
import { Tools } from '@app/utils/tools';

interface PresetModalProps extends ModalProps {
  filters: any;
  presetData?: any;
  onSuccessCreated?: (presetId: string) => void;
}

export const PresetModal: FC<PresetModalProps> = ({ filters, presetData, onSuccessCreated, ...props }) => {
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const [presetName, setPresetName] = useState(Tools.isPresent(presetData) ? presetData?.name : null);

  const { mutate: createPresetMutate, isLoading: isCreatePresetLoading } = useMutation({
    mutationFn: (data: any) => createPreset(data),
    onSuccess: async (data) => {
      toast.success(i18n.t('messages.success.createPreset'));
      await Promise.all([queryClient.invalidateQueries('preset_list'), queryClient.invalidateQueries('preset')]);

      if (onSuccessCreated) {
        onSuccessCreated(data?.id);
      }

      props.onCancel(null);
    },
  });

  const { mutate: updatePresetNameMutate, isLoading: isUpdatePresetNameLoading } = useMutation({
    mutationFn: (data: any) => updatePreset(presetData?.id, data),
    onSuccess: async () => {
      toast.success(i18n.t('messages.success.updatePreset'));
      await Promise.all([queryClient.invalidateQueries('preset_list'), queryClient.invalidateQueries('preset')]);
      props.onCancel(null);
    },
  });

  const updatePresetNameHandler = () => {
    const payload = {
      name: presetName,
      metadata: presetData?.metadata,
    };
    updatePresetNameMutate(payload);
  };

  const createPresetHandler = () => {
    const payload = {
      name: presetName,
      metadata: filters,
    };
    createPresetMutate(payload);
  };

  return (
    <Modal
      {...props}
      width={450}
      centered
      footer={false}
      title={
        <Typography.Title level={3}>
          {Tools.isPresent(presetData)
            ? i18n.t('searchFilterBar.presetActions.modal.renameTitle')
            : i18n.t('searchFilterBar.presetActions.modal.addTitle')}
        </Typography.Title>
      }
    >
      <Form layout={'vertical'} className={'p-2'}>
        <Form.Item label={i18n.t('searchFilterBar.presetActions.modal.nameLabel')}>
          <Input
            ref={inputRef}
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            onPressEnter={() => {
              if (presetName) {
                Tools.isPresent(presetData) ? updatePresetNameHandler() : createPresetHandler();
              }
            }}
          />
        </Form.Item>

        <Button
          loading={isCreatePresetLoading || isUpdatePresetNameLoading}
          type={'primary'}
          disabled={!presetName}
          className={'w-50 mt-5 mx-auto'}
          onClick={() => (Tools.isPresent(presetData) ? updatePresetNameHandler() : createPresetHandler())}
        >
          {i18n.t('searchFilterBar.presetActions.save')}
        </Button>
      </Form>
    </Modal>
  );
};
