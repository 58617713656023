import axiosInstance from '@app/config/api/config';

export async function getPresetList() {
  const res = await axiosInstance.get('/filters_presets/refund_requests');

  return res.data;
}

export async function getPreset(presetId: string) {
  const res = await axiosInstance.get(`/filters_presets/refund_requests/${encodeURIComponent(presetId)}`);

  return res.data;
}

export async function createPreset(payload: any) {
  const res = await axiosInstance.post('/filters_presets/refund_requests/', payload);

  return res.data;
}

export async function updatePreset(presetId: string, payload: any) {
  const res = await axiosInstance.put(`/filters_presets/refund_requests/${encodeURIComponent(presetId)}`, payload);

  return res.data;
}

export async function deletePreset(presetId: string) {
  const res = await axiosInstance.delete(`/filters_presets/refund_requests/${presetId}`);

  return res.data;
}

export async function updateDefaultPresetType(presetId: string, payload: boolean) {
  const res = await axiosInstance.post(`/filters_presets/refund_requests/${encodeURIComponent(presetId)}/default`, {
    isDefault: payload,
  });

  return res.data;
}

export async function updateGlobalPresetType(presetId: string, payload: boolean) {
  const res = await axiosInstance.post(`/filters_presets/refund_requests/${encodeURIComponent(presetId)}/global`, {
    isGlobal: payload,
  });

  return res.data;
}
