import React, { FC, useMemo, useState } from 'react';
import { Button, Popconfirm, Space, Spin, Tooltip, Typography, Upload } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { addAttachment, deleteAttachment, getAllAttachmentBlob, getAttachmentBlob, getAttachments } from '@app/api';
import { RQContent } from '@app/components/rq_content';
import { bytesToMegabytes } from '@app/utils/calculations/calculations';
import { AttachmentPreviewModal } from '@app/components/modals/attachment_preview_modal';
import { Tools } from '@app/utils/tools';
import { colors, FILES_ACCEPT_STRING, MAX_FILE_SIZE_MB } from '@app/config/constants';
import { ThemeContext } from '@app/global_provider';
import { ThemeEnum } from '@app/types/theme';

import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '@app/assets/icons/download.svg';

interface AttachmentsProps {
  requestId: string;
  requestNumber: string;
  isActionsAllowed?: boolean;
  handleAttachmentsStatus: (v: boolean) => void;
}

export const Attachments: FC<AttachmentsProps> = ({
  requestId,
  requestNumber,
  isActionsAllowed,
  handleAttachmentsStatus,
}) => {
  const { theme } = React.useContext<any>(ThemeContext);

  const [previewFile, setPreviewFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadAllButtonEnabled, setIsDownloadAllButtonEnabled] = useState(false);
  const [currentDownloadingFileId, setCurrentDownloadingFileId] = useState(null);

  const {
    status: attachmentsStatus,
    data: attachments,
    refetch: refetchAttachments,
  } = useQuery({
    queryKey: ['attachments'],
    queryFn: () => getAttachments(requestId),
    onSuccess: (data) => {
      setIsDownloadAllButtonEnabled(Tools.isPresent(data));
      handleAttachmentsStatus(Tools.isBlank(data));
    },
  });

  const { mutate: addAttachmentMutate, isLoading: isAddAttachmentLoading } = useMutation({
    mutationFn: (data: any) => addAttachment(requestId, data),
    onSuccess: async () => {
      refetchAttachments();
    },
  });

  const { mutate: deleteAttachmentMutate, isLoading: isDeleteAttachmentLoading } = useMutation({
    mutationFn: (fileId: string) => deleteAttachment(requestId, fileId),
    onSuccess: async () => {
      refetchAttachments();
    },
  });

  const { mutate: downloadFile, isLoading: isDownloadFileLoading } = useMutation({
    mutationFn: (data: any) => getAttachmentBlob(requestId, data.uid),
    onSuccess: (data, variables) => {
      const blobUrl = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = variables.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    },
  });

  const { mutate: downloadAllFiles, isLoading: isDownloadAllFileLoading } = useMutation({
    mutationFn: () => getAllAttachmentBlob(requestId),
    onSuccess: (data) => {
      const blobUrl = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${requestNumber}-all-attachments.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    },
  });

  const fileList = useMemo(() => {
    return attachments?.map((a) => ({
      name: a.blob.filename,
      uid: a.id,
      url: a.url,
      size: a.blob.byteSize,
    }));
  }, [attachments]);

  const isDuplicateFile = (file) =>
    attachments.some((a) => a.blob.filename === file.name && a.blob.byteSize === file.size);

  const uploadHandler = async (options: any) => {
    const { file, onSuccess, onError } = options;

    if (isDuplicateFile(file)) {
      toast.info(i18n.t('attachments.fileIsAlreadyExist'));
      return;
    }

    if (bytesToMegabytes(file.size) > MAX_FILE_SIZE_MB) {
      toast.error(i18n.t('attachments.sizeError'));
      return;
    }

    const formData = new FormData();
    formData.append('attachment', file);

    addAttachmentMutate(formData, {
      onSuccess: () => {
        onSuccess('Ok');
      },
      onError: (err) => {
        onError(err);
      },
    });
  };

  const downloadHandler = (file) => {
    setCurrentDownloadingFileId(file?.uid);
    downloadFile(file);
  };

  return (
    <RQContent status={attachmentsStatus}>
      <Space className={'w-100 mt-4'} direction={'vertical'}>
        <Space size={'large'}>
          <Typography.Title level={3}>{i18n.t('attachments.title')}</Typography.Title>
          <Button
            icon={<DownloadIcon opacity={isDownloadAllButtonEnabled ? 1 : 0.5} width={20} height={20} />}
            size={'small'}
            type={'default'}
            loading={isDownloadAllFileLoading}
            onClick={() => !isDownloadAllFileLoading && downloadAllFiles()}
            disabled={!isDownloadAllButtonEnabled}
          >
            {i18n.t('attachments.downloadAll')}
          </Button>
        </Space>
        <Dragger
          disabled={!isActionsAllowed || isAddAttachmentLoading || isDeleteAttachmentLoading}
          className={`dragger ${!isActionsAllowed && 'hide-dragger'}`}
          accept={FILES_ACCEPT_STRING}
          multiple
          fileList={fileList}
          onPreview={(file) => {
            setIsModalOpen(true);
            setPreviewFile(file);
          }}
          iconRender={(file) => (
            <Space>
              <span>{bytesToMegabytes(file.size)}MB</span>
              <span>{file.uid}MB</span>
              <Tooltip title={i18n.t('buttons.download')}>
                <Button
                  className={'p-0 mx-2 collapsed'}
                  size={'small'}
                  type={'dashed'}
                  loading={isDownloadFileLoading && file?.uid === currentDownloadingFileId}
                  onClick={() => !isDownloadFileLoading && downloadHandler(file)}
                  icon={
                    <DownloadIcon
                      fill={theme === ThemeEnum.Light ? colors.darkViolet : colors.violet}
                      className={`m-0 ${isDownloadFileLoading && 'opacity-50'}`}
                      width={20}
                      height={20}
                    />
                  }
                />
              </Tooltip>
            </Space>
          )}
          customRequest={uploadHandler}
          showUploadList={{
            showDownloadIcon: true,
            downloadIcon: () => <DownloadIcon />,
            removeIcon: (file) => (
              <Popconfirm
                title={
                  <>
                    <Typography.Title level={2}>{i18n.t('popconfirm.deleteAttachmentTitle')}</Typography.Title>
                    <p>{i18n.t('popconfirm.deleteAttachmentDescription')}</p>
                  </>
                }
                onConfirm={() => deleteAttachmentMutate(file.uid)}
                icon={null}
                okText={i18n.t('popconfirm.yes')}
                cancelText={i18n.t('popconfirm.no')}
                placement={'top'}
                okButtonProps={{ size: 'large', type: 'ghost' }}
                cancelButtonProps={{ size: 'large', type: 'text' }}
              >
                <DeleteIcon />
              </Popconfirm>
            ),
          }}
        >
          {isAddAttachmentLoading || isDeleteAttachmentLoading ? (
            <Spin size={'large'} />
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <DownloadIcon fill={theme === ThemeEnum.Light ? colors.darkViolet : colors.violet} />
              </p>
              <p className="ant-upload-text">{i18n.t('attachments.description')}</p>
              <p className="ant-upload-hint">{i18n.t('attachments.fileInfo')}</p>
            </>
          )}
        </Dragger>
        {previewFile && (
          <AttachmentPreviewModal
            key={previewFile.uid}
            previewId={previewFile.uid}
            requestId={requestId}
            open={isModalOpen}
            previewName={previewFile.name}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Space>
    </RQContent>
  );
};
