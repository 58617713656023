import React, { useContext, useMemo } from 'react';
import { Tag } from 'antd';

import { LanguageContext } from '@app/global_provider';
import { useQueryState } from '@app/hooks/use-query-state';
import { Tools } from '@app/utils/tools';

import { ReactComponent as CloseIcon } from '@app/assets/icons/close.svg';

export const FilterTags = () => {
  const { isRtl } = useContext<any>(LanguageContext);
  const [urlParams, setUrlParams] = useQueryState();

  const removeParamHandler = (paramKey: string) => {
    setUrlParams({ ...urlParams, [paramKey]: null });
  };

  const filteredParams = useMemo(() => {
    const { presetId, search, ...otherParams } = urlParams;
    return Object.keys(otherParams);
  }, [urlParams]);

  if (Tools.isPresent(urlParams?.presetId) || Tools.isBlank(filteredParams)) {
    return null;
  }

  return (
    <>
      {filteredParams?.map((param) => (
        <Tag key={param} color="volcano" className="d-flex align-items-center fs-7">
          {Tools.humanize(param)}
          <CloseIcon
            onClick={() => removeParamHandler(param)}
            style={{ cursor: 'pointer' }}
            className={`${isRtl ? 'me-1' : 'ms-1'}`}
            width={15}
            height={15}
          />
        </Tag>
      ))}
    </>
  );
};
