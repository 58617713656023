import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Input, Space, Table, Typography } from 'antd';
import { NumericFormat } from 'react-number-format';

import { Safebox } from '@app/types';
import { i18n } from '@app/translations/i18n.config';
import { formatSafeboxState } from '@app/utils/calculations/calculations';
import { coinDenomination, defaultState } from '@app/components/tables/safebox_table/constants';

import { ReactComponent as SaveIcon } from '@app/assets/icons/save.svg';
import { ReactComponent as UndoIcon } from '@app/assets/icons/undo.svg';
import { ReactComponent as MoneyIcon } from '@app/assets/icons/money.svg';
import { ReactComponent as CoinIcon } from '@app/assets/icons/coin.svg';
import { Tools } from '@app/utils/tools';

interface SafeBoxTableProps {
  isEditMode: boolean;
  onDiscard: () => void;
  onSave: (data: any) => void;
  safebox: Safebox;
  isLoading?: boolean;
}

export const SafeBoxTable: FC<SafeBoxTableProps> = ({ isEditMode, onDiscard, onSave, safebox, isLoading }) => {
  const initialData = useMemo(
    () =>
      formatSafeboxState({
        ...defaultState,
        ...safebox?.state,
      }),
    [safebox],
  );

  const [tableData, setTableData] = useState([]);

  const handleQuantityChange = (value, record) => {
    if (!Tools.isDigit(value)) {
      return;
    }
    const updatedData = tableData.map((item) => {
      if (item.denomination === record.denomination) {
        const quantity = Number(value);
        return {
          ...item,
          quantity,
          amount: quantity * record.denomination || 0,
        };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const handleAmountChange = (value, record) => {
    if (!Tools.isDigit(value)) {
      return;
    }
    const updatedData = tableData.map((item) => {
      if (item.denomination === record.denomination) {
        const amount = Number(value);
        const quantity = amount / record.denomination;
        return {
          ...item,
          quantity: Math.floor(quantity) ?? 0,
          amount,
        };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const getTotal = (stateTotal, data) => {
    if (isEditMode) {
      return data.reduce((sum, item) => sum + item.amount, 0);
    } else {
      return stateTotal;
    }
  };

  const saveHandler = () => {
    onSave(tableData);
  };

  const discardHandler = () => {
    setTableData(initialData);
    onDiscard();
  };

  useEffect(() => {
    setTableData(initialData);
  }, [safebox]);

  const columns = [
    {
      title: i18n.t('safebox.tableTitle.denomination'),
      dataIndex: 'denomination',
      key: 'denomination',
      width: '33.33%',
      render: (record) => (
        <Space align={'center'} direction={'horizontal'} style={{ minHeight: '38px' }}>
          {coinDenomination.includes(record) ? <CoinIcon className={'mt-1'} /> : <MoneyIcon className={'mt-1'} />}{' '}
          {record}
        </Space>
      ),
    },
    {
      title: i18n.t('safebox.tableTitle.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '33.33%',
      render: (quantity, record) =>
        isEditMode ? (
          <Input
            min={0}
            maxLength={10}
            value={quantity}
            style={{ width: 80 }}
            onChange={(e) => handleQuantityChange(e.target.value, record)}
          />
        ) : (
          quantity
        ),
    },
    {
      title: i18n.t('safebox.tableTitle.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: '33.33%',
      render: (amount, record) =>
        isEditMode ? (
          <Input
            min={0}
            step={record.denomination}
            value={amount}
            maxLength={10}
            style={{ width: 80 }}
            onChange={(e) => handleAmountChange(e.target.value, record)}
          />
        ) : (
          amount
        ),
    },
  ];

  return (
    <>
      <Table className={'min-padding'} dataSource={tableData} pagination={false} columns={columns} />
      <div className={'d-flex justify-content-end align-items-center mt-3'}>
        <Typography.Title level={3} style={{ textAlign: 'left' }} className={'m-0 text-grey'}>
          {i18n.t('refund.total')}
        </Typography.Title>
        <Typography.Title level={3} style={{ minWidth: '33.33%', padding: '0 8px' }} className={'m-0'}>
          <NumericFormat displayType={'text'} value={getTotal(safebox?.totalAmount, tableData)} thousandSeparator="," />
          <span className={'fs-6'}>د.إ</span>
        </Typography.Title>
      </div>
      {isEditMode && (
        <Space className={'w-100 justify-content-between my-4 px-3'}>
          <Button type={'default'} icon={<UndoIcon />} onClick={discardHandler} disabled={isLoading}>
            {i18n.t('safebox.discard')}
          </Button>
          <Button type={'primary'} icon={<SaveIcon />} onClick={saveHandler} disabled={isLoading} loading={isLoading}>
            {i18n.t('safebox.save')}
          </Button>
        </Space>
      )}
    </>
  );
};
